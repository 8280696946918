import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Application } from '../models/application.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { Disclaimer } from '../models/disclaimer.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProgramCertificationService {

  private application: Application;
  private disclaimer : Disclaimer;
  private disclaimerData : any;

  constructor(private http: HttpClient,
    private errorHandler: ErrorHandlerService) { }

  /** Return disclaimer based on programCode, country code and language **/
  getDisclaimer(programCode :string[], citzenshipCtryCode: string, lang : string) : Observable <any> {

    let disclaimerUrl = environment.uriEndpoint + environment.apiVersion + 'goesapp/reference/disclaimer';
      disclaimerUrl += '?programCode=' + programCode[0];
      if (programCode.length > 1) {
          for (let i=1; i < programCode.length; i++ )
          disclaimerUrl += "&programCode=" + programCode[i];
      }

      disclaimerUrl += '&citzenshipCtryCode=' + citzenshipCtryCode +'&lang=' + lang;

    return this.http.get( disclaimerUrl ).pipe(
      map((res:any) => {

        this.disclaimerData = res;
        return res;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }
}
